import './App.css';

function App() {
  return (
    <div className="App">
      
    <div class="titan-background">
        <span class="stars stars-L"></span>
        <span class="stars stars-M"></span>
        <span class="stars stars-S"></span>
    </div>
    
    <div class="marvellous-container">
        <div class="header">
            <h1><span class="title-marvel">Thanos</span> <span class="title-juca">Juca</span> </h1>
            <h2>
                Site para localizar os projetos concluidos.
            </h2>
        </div>
        
        <div class="installation-instructions">
            <h3>Projetos</h3>
            <a target="_blank" href="https://josuejuca.github.io/netflix/"><code># Projeto 001 - Netflix HomePage HTML $</code></a>
            <a target="_blank" href="https://josuejuca.com/"><code># Projeto 002 - Portfolio Josué Juca $</code></a>
            <a target="_blank" href="https://github.com/josuejuca/Jogo-da-Velha"><code># Projeto 003 - Jogo da velha com Python (Tk) $</code></a>
            <a target="_blank" href="https://nwtelecom.tk"><code># Projeto 004 - NW Telecom $</code></a>
            <a target="_blank" href="https://biolink.josuejuca.com/"><code># Projeto 005 - BioLink (Vue.js) $</code></a>
            <a target="_blank" href=""><code># Projeto 006 - $</code></a>
            <a target="_blank" href=""><code># Projeto 007 - $</code></a>
            <a target="_blank" href=""><code># Projeto 008 - $</code></a>
            <a target="_blank" href=""><code># Projeto 009 - $</code></a>
            <a target="_blank" href=""><code># Projeto 010 - $</code></a>
            <a target="_blank" href=""><code># Projeto 011 - $</code></a>
            <a target="_blank" href=""><code># Projeto 012 - $</code></a>
            <a target="_blank" href=""><code># Projeto 013 - $</code></a>
            <a target="_blank" href=""><code># Projeto 014 - $</code></a>
            <a target="_blank" href=""><code># Projeto 015 - $</code></a>
            <a target="_blank" href=""><code># Projeto 016 - $</code></a>
            <a target="_blank" href=""><code># Projeto 017 - $</code></a>
            <a target="_blank" href=""><code># Projeto 018 - $</code></a>
            <a target="_blank" href=""><code># Projeto 019 - $</code></a>
            <a target="_blank" href=""><code># Projeto 020 - $</code></a>
        </div>
        
        <div class="usage-instructuons">
            <h3>Ferramentas</h3>
            <code># Visual Studio Code $</code>
            <code># Netlify $</code>
            <code># GitHub $ </code>
        </div>
        <div class="usage-instructuons">
            <h3>Criador</h3>
            <code># Josué Juca $</code>
        </div>
        <div class="footer">
            <img src="" alt="" class=""/>
            <p>
                Esses sites são utilizados para fins educacionais feito pelo 
                 <a class="aeus67Ac" href="https://josuejuca.com"> Josué Juca</a>, não temos intenção de violar os direitos de ninguém.  
            </p>
        </div>
    </div>
    </div>
  );
}

export default App;
